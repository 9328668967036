<script setup lang="ts">
import CornerLogo from '@/components/CornerLogo.vue';
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import PreloadLink from '@/components/PreloadLink.vue';
import Button from '@/components/ui/button/Button.vue';
import { ArrowRightIcon } from '@radix-icons/vue';
import { useSeoMeta } from '@unhead/vue';
import { useEventListener } from '@vueuse/core';
import { Head } from 'vike-vue/Head';
import { useData } from 'vike-vue/useData';
import { ref } from 'vue';

interface PageData {
  title: string;
  description: string;
  logo: string;
}

const PARALLAX_STRENGTH = 20;
const y = ref(0);
const pageData = useData<PageData>();

function onScroll() {
  y.value = window.scrollY * (PARALLAX_STRENGTH / 100);
}

useEventListener('scroll', onScroll);

useSeoMeta({
  title: pageData.title,
  description: pageData.description,
});
</script>

<template>
  <Head>
    <title>{{ pageData.title }}</title>
    <meta name="description" :content="pageData.description" />

    <meta name="og:image" :content="pageData.logo" />
    <meta name="og:image:width" content="256" />
    <meta name="og:image:height" content="256" />
  </Head>

  <main>
    <Header />

    <section class="parallax hero" :style="{ '--y': `${y}px` }">
      <CornerLogo class="hidden md:block" />

      <div class="hero-content">
        <div class="md:hidden"></div>

        <div
          class="text-center mix-blend-difference text-white text-[4rem] sm:text-[5rem] md:text-[6rem] lg:text-[7.5rem] leading-[1.1] tracking-[0] font-thin uppercase"
        >
          <h1>Portable.</h1>
          <h1>Personal.</h1>
          <h1>Premium.</h1>
        </div>

        <Button
          variant="accent"
          size="lg"
          class="h-14 flex items-center bg-accent hover:bg-accent-hover text-white rounded-2xl mt-20"
        >
          <PreloadLink to="/products/aero-pro">
            <span class="text-lg font-semibold">Continue</span>
          </PreloadLink>
          <ArrowRightIcon class="size-6" />
        </Button>
      </div>
    </section>

    <Footer />
  </main>
</template>

<style>
.hero {
  @apply relative bg-primary rounded-lg p-4 col-span-12 h-[calc(80vh-6.75rem)] sm:h-[calc(100vh-6.75rem)] md:h-[calc(100vh-3rem)] min-h-[600px] sm:min-h-[800px];

  > .hero-content {
    @apply w-full h-full flex flex-col items-center pointer-events-none;
    /* Adjust positioning for different screen sizes */
    /* Small screens: position content towards the top with less padding */
    @apply justify-start pt-16 pb-8 sm:pt-24 sm:pb-12;
    
    /* Medium screens and up: use different positioning */
    @apply sm:justify-between sm:py-24 md:justify-center md:mt-0;

    > * {
      pointer-events: auto;
    }
  }
}

.parallax {
  @apply bg-cover md:bg-contain;

  background-image: url('@/assets/img/hero.webp');

  @media screen and (max-width: 768px) {
    background-image: url('@/assets/img/hero-mobile.webp');    
  }

  @media screen and (min-width: 2160px) {
    background-image: url('@/assets/img/hero.png');
  }

  background-position-x: center;
  background-position-y: calc(50% + var(--y));
  background-repeat: no-repeat;
}
</style>
